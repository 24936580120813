import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/components/layout/index.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const TableOfContents = makeShortcode("TableOfContents");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <TableOfContents title="On this page" mdxType="TableOfContents">
      <div className="table-of-contents">
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#design-resources"
            }}>{`Design resources`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#developer-resources"
            }}>{`Developer resources`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#brand-resources"
            }}>{`Brand resources`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#accessibility-resources"
            }}>{`Accessibility resources`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#copy-resources"
            }}>{`Copy resources`}</a></li>
        </ul>
      </div>
    </TableOfContents>
    <h2 {...{
      "id": "design-resources",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#design-resources",
        "aria-label": "design resources permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Design resources`}</h2>
    <h3>{`Figma UI design kits`}</h3>
    <p>{`We have UI design kits for use in Figma for Sainsbury’s, Argos, Nectar, Habitat and Tu. Our UI design kits contain colour styles, text styles, grid styles, components and icons.`}</p>
    <p>{`If you’re a Sainsbury’s colleague, the UI design libraries will already be available to you in your Figma file. Visit ‘libraries’ and ensure the branded Figma libraries are enabled.`}</p>
    <p>{`If you’re a third party or designing outside the Sainsbury’s organisation, you’ll need to `}<a parentName="p" {...{
        "href": "https://www.figma.com/@sainsburys"
      }}>{`duplicate the UI design kits from our community page`}</a>{`.`}</p>
    <hr></hr>
    <h2 {...{
      "id": "developer-resources",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#developer-resources",
        "aria-label": "developer resources permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Developer resources`}</h2>
    <h3>{`Fable`}</h3>
    <p>{`Fable is a component library covering common UI needs. More in-depth information can be found on our `}<a parentName="p" {...{
        "href": "https://sainsburys-tech.github.io/design-systems/?path=/docs/documentation-quick-start--quickstart"
      }}>{`Storybook site`}</a>{`.`}</p>
    <h5>{`Private registry`}</h5>
    <p><strong parentName="p">{`Please note,`}</strong>{` the latest Fable packages are privately hosted and requires you to have a token to be able to download and install them. Without this, you will receive an error from running the above command. More information on this can be found at our `}<a parentName="p" {...{
        "href": "https://sainsburys-tech.github.io/design-systems/?path=/docs/documentation-quick-start--quickstart"
      }}>{`getting started guide`}</a>{` for a step by step walkthrough.`}</p>
    <h5>{`Styling`}</h5>
    <p>{`Fable Style is a peer dependency of this library and is needed for the relevant styles to be applied to components.`}</p>
    <h3>{`GitHub`}</h3>
    <p>{`See code documentation, raise issues or make suggestions at our GitHub page.`}</p>
    <p><a parentName="p" {...{
        "href": "https://github.com/sainsburys-tech/design-systems"
      }}>{`Visit Design Systems GitHub`}</a></p>
    <h3>{`Browser support`}</h3>
    <p>{`Fable components and styles are supported in the following browsers:`}</p>
    <ul>
      <li parentName="ul">{`Google Chrome (latest)`}</li>
      <li parentName="ul">{`Microsoft Edge (latest)`}</li>
      <li parentName="ul">{`Mozilla Firefox (latest)`}</li>
      <li parentName="ul">{`Safari (latest)`}</li>
    </ul>
    <p>{`Some products currently using Fable may be using browsers not in this list. If you have any questions about our supported browsers, you can contact us on our `}<a parentName="p" {...{
        "href": "https://teams.microsoft.com/l/channel/19%3aaad9802e55b146dab1e3d2fb4a0fc52d%40thread.skype/General?groupId=a5c53840-8b6c-4d88-a798-8e2550ac34ef&tenantId=e11fd634-26b5-47f4-8b8c-908e466e9bdf"
      }}>{`Microsoft Teams channel`}</a>{`.`}</p>
    <hr></hr>
    <h2 {...{
      "id": "brand-resources",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#brand-resources",
        "aria-label": "brand resources permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Brand resources`}</h2>
    <p>{`To help your experiences look, sound and feel like the brand you’re designing for, each brand using our design system follows a set of brand guidelines.`}</p>
    <p>{`If you are a Sainsbury’s colleague, you can read each of the guidelines below. Otherwise, get in touch with our brand team and they’ll supply you with a copy.`}</p>
    <p><a parentName="p" {...{
        "href": "https://jsainsbury.sharepoint.com/:b:/s/lunateam/EWVhIEeue6xJgVpYsyWWgQUBul5a69Uyjk1gqQt-foodcg?e=m1L5ra"
      }}>{`Sainsbury’s internal brand guidelines PDF`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://jsainsbury.sharepoint.com/:b:/s/lunateam/Ea3lBNlwoUNNvdBkMfsxdj0BiIyCM45uLUh0mzDQvN5RMw?e=VUuALc"
      }}>{`Argos internal brand guidelines PDF`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://jsainsbury.sharepoint.com/:b:/s/lunateam/EUeFfpPfNcxFkaAbNQ4uHw4B3AsB_C_KFB9Ow30kupDniw?e=Gw533W"
      }}>{`Nectar internal brand guidelines PDF`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://jsainsbury.sharepoint.com/:b:/s/lunateam/EUM2JRrhgexFnIDdv-EH14MBn2_9F7dvTKkn8FCowuaWgA?e=itgEYA"
      }}>{`Habitat internal brand guidelines PDF`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://jsainsbury.sharepoint.com/:b:/s/lunateam/ESLuQ2njjnBLhlL118J-txkB64e7vOw67GCHr45s7EkXUw?e=xXrB8O"
      }}>{`Tu internal brand guidelines PDF`}</a></p>
    <hr></hr>
    <h2 {...{
      "id": "accessibility-resources",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#accessibility-resources",
        "aria-label": "accessibility resources permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Accessibility resources`}</h2>
    <h3>{`Web accessibility guidance`}</h3>
    <p>{`For comprehensive guidance on writing, designing, and developing for accessibility, we recommend the `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/design-develop/"
      }}>{`W3C Web Accessibility Initiative tips and tutorials`}</a>{`.`}</p>
    <p>{`We also recommend the `}<a parentName="p" {...{
        "href": "https://w3c.github.io/wcag/guidelines/22/"
      }}>{`Web Content Accessibility Guidelines (WCAG) 2.2`}</a>{` for learning how to make web content more accessible.`}</p>
    <h3>{`Web accessibility testing`}</h3>
    <p>{`To test and detect accessibility problems as you build experiences, you can `}<a parentName="p" {...{
        "href": "https://chrome.google.com/webstore/detail/axe-web-accessibility-tes/lhdoppojpmngadmnindnejefpokejbdd"
      }}>{`add the Axe Chrome extension`}</a>{` to your computer.`}</p>
    <p>{`For end-to-end and continuous integration accessibility testing, you can use `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/axe-testcafe"
      }}>{`axe-testcafe`}</a>{` or `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/cypress-axe"
      }}>{`cypress-axe`}</a>{`.`}</p>
    <p>{`If you need any help getting set up with these accessibility resources, you can `}<a parentName="p" {...{
        "href": "https://teams.microsoft.com/l/channel/19%3aaad9802e55b146dab1e3d2fb4a0fc52d%40thread.skype/General?groupId=a5c53840-8b6c-4d88-a798-8e2550ac34ef&tenantId=e11fd634-26b5-47f4-8b8c-908e466e9bdf"
      }}>{`contact us`}</a>{` with any questions.`}</p>
    <hr></hr>
    <h2 {...{
      "id": "copy-resources",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#copy-resources",
        "aria-label": "copy resources permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Copy resources`}</h2>
    <h3>{`Hemingway`}</h3>
    <p>{`Checks your writing and highlights any complicated sentences. It also tells you if you’ve written in the passive voice, which we recommend you avoid in your writing.`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.hemingwayapp.com/"
      }}>{`Try Hemingway`}</a></p>
    <h3>{`Grammarly`}</h3>
    <p>{`Checks your spelling and grammar to make sure there’s no mistakes in your writing.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.grammarly.com/"
      }}>{`Try Grammarly`}</a></p>
    <h3>{`GOV.UK style guide`}</h3>
    <p>{`A style guide that’s a good fallback for any words or phrases not covered in `}<a parentName="p" {...{
        "href": "/copy/style-guide/"
      }}>{`our style guide`}</a>{`.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.gov.uk/guidance/style-guide/a-to-z-of-gov-uk-style"
      }}>{`Read GOV.UK style guide`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      